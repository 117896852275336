var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('b-row', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Banner")])]), _c('b-col', [_vm.allowCreate() ? _c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-lg",
      modifiers: {
        "modal-lg": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.ModalShow();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah Banner")], 1)], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "lg",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.Modal = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Thumbnail (Ukuran maks: 1440x424px untuk slide)",
      "label-for": "Thumbnail"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.image,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "image", $$v);
      },
      expression: "form.image"
    }
  })], 1)], 1), _vm.form.image2 != null ? _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', [_vm._v("Thumbnail : ")]), _c('br'), _c('b-img', {
    attrs: {
      "src": _vm.apiFile + _vm.form.image2,
      "fluid": "",
      "alt": _vm.form.image
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Link (Opsional)",
      "label-for": "Link"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Link",
      "placeholder": "Link",
      "type": "url"
    },
    model: {
      value: _vm.form.link,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "link", $$v);
      },
      expression: "form.link"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Order",
      "label-for": "Order"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Order",
      "placeholder": "Order"
    },
    model: {
      value: _vm.form.order,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "order", $$v);
      },
      expression: "form.order"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Aktif/Nonaktif (Opsional)",
      "label-for": "Aktif/Nonaktif"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": "",
      "id": "Active",
      "placeholder": "Active"
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-upload",
      "centered": "",
      "size": "lg",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.UploadThumbnail($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.ModalUploadThumbnail = false;

              _vm.getDataById();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalUploadThumbnail,
      callback: function callback($$v) {
        _vm.ModalUploadThumbnail = $$v;
      },
      expression: "ModalUploadThumbnail"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Thumbnail (Ukuran maks: 1440x424px untuk slide)",
      "label-for": "Thumbnail"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.image,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "image", $$v);
      },
      expression: "form.image"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-caption-truncate"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.bannerData
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(active)",
      fn: function fn(data) {
        return [data.item.active == 1 ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" Aktif ")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" Tidak Aktif ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_vm.bannerData.length == 1 ? _c('div', [_vm.allowUpdate() || _vm.allowDelete() ? _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                staticStyle: {
                  "height": "100px"
                },
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUpload(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UploadCloudIcon"
          }
        }), _c('span', [_vm._v("Upload Thumbnail")])], 1), _vm.allowUpdate() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.allowDelete() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.bannerData.length != 1 ? _c('div', [_vm.allowUpdate() || _vm.allowDelete() ? _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUpload(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UploadCloudIcon"
          }
        }), _c('span', [_vm._v("Upload Thumbnail")])], 1), _vm.allowUpdate() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.allowDelete() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()];
      }
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          staticClass: "mb-1"
        }, [_c('strong', [_vm._v("Thumbnail : ")]), _c('br'), _c('b-img', {
          staticStyle: {
            "max-width": "500px"
          },
          attrs: {
            "src": _vm.apiFile + row.item.image,
            "alt": row.item.image
          }
        })], 1)], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" Hide Details ")])], 1)];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('card-popup')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }